const state = {
  currentChannel: 'Website',
  currentStep: 0,
  inboxId: null,
  submitTriggered: false,
  selectedWaProvider: 'whatsapp_cloud',
};

const mutations = {
  setCurrentChannel(state, step) {
    state.currentChannel = step;
  },
  setCurrentStep(state, step) {
    state.currentStep = step;
  },
  setInboxId(state, id) {
    state.inboxId = id;
  },
  triggerSubmit(state) {
    state.submitTriggered = true;
  },
  resetSubmit(state) {
    state.submitTriggered = false;
  },
  setSelectedWaProvider(state, provider) {
    state.selectedWaProvider = provider;
  },
};

const actions = {
  setCurrentChannel({ commit }, step) {
    commit('setCurrentChannel', step);
  },
  setCurrentStep({ commit }, step) {
    commit('setCurrentStep', step);
  },
  setInboxId({ commit }, id) {
    commit('setInboxId', id);
  },
  triggerSubmit({ commit }) {
    commit('triggerSubmit');
    // Reset submit state after a short delay to allow re-triggering
    setTimeout(() => {
      commit('resetSubmit');
    }, 0);
  },
  setSelectedWaProvider({ commit }, provider) {
    commit('setSelectedWaProvider', provider);
  },
};

const getters = {
  getCurrentChannel: state => state.currentChannel,
  getCurrentStep: state => state.currentStep,
  getInboxId: state => state.inboxId,
  isSubmitTriggered: state => state.submitTriggered,
  getSelectedWaProvider: state => state.selectedWaProvider,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
