import PackagesAPI from '../../api/packages';
import types from '../mutation-types';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
  },
};

export const getters = {
  getPackages($state) {
    return $state.records;
  },
  getPackage: $state => packageId => {
    const packageItem = $state.records.find(
      record => record.id === Number(packageId)
    );
    return packageItem || {};
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  async fetchPackages({ commit }) {
    commit(types.SET_PACKAGES_UI_FLAG, { isFetching: true });
    try {
      const response = await PackagesAPI.getPackages();
      commit(types.SET_PACKAGES, response.data);
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    } finally {
      commit(types.SET_PACKAGES_UI_FLAG, { isFetching: false });
    }
  },
  async fetchPackage({ commit }, packageId) {
    commit(types.SET_PACKAGES_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await PackagesAPI.getPackage(packageId);
      commit(types.SET_PACKAGE, response.data);
      return response.data;
    } catch (error) {
      commit(types.SET_PACKAGES_UI_FLAG, { isFetchingItem: false });
      throw new Error(error);
    } finally {
      commit(types.SET_PACKAGES_UI_FLAG, { isFetchingItem: false });
    }
  },
};

export const mutations = {
  [types.SET_PACKAGES_UI_FLAG]($state, uiFlag) {
    state.uiFlags = { ...$state.uiFlags, ...uiFlag };
  },
  [types.SET_PACKAGES]($state, packages) {
    $state.records = packages;
  },
  [types.SET_PACKAGE]($state, packageItem) {
    const index = $state.records.findIndex(
      record => record.id === packageItem.id
    );
    if (index !== -1) {
      state.records.splice(index, 1, packageItem);
    } else {
      state.records.push(packageItem);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
