const state = {
  payment: {
    amount: 0,
    package_id: 0,
    period: 0,
    name: '',
  },
};

const getters = {
  getPayment: $state => $state.payment,
};

const mutations = {
  setPayment: ($state, payment) => {
    $state.payment = payment;
  },
};

const actions = {
  setPayment: ({ commit }, payment) => {
    commit('setPayment', payment);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
