export default {
  computed: {
    hostURL() {
      return window.chatminConfig.hostURL;
    },
    vapidPublicKey() {
      return window.chatminConfig.vapidPublicKey;
    },
    enabledLanguages() {
      return window.chatminConfig.enabledLanguages;
    },
    isEnterprise() {
      return window.chatminConfig.isEnterprise === 'true';
    },
    enterprisePlanName() {
      // returns "community" or "enterprise"
      return window.chatminConfig?.enterprisePlanName;
    },
  },
};
