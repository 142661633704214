import ToastContainer from '../components/Toast/ToastContainer.vue';

const ToastPlugin = {
  install(Vue) {
    const ToastConstructor = Vue.extend(ToastContainer);
    let instance;

    Vue.mixin({
      beforeMount() {
        if (!instance && this.$root === this) {
          const container = document.createElement('div');
          container.id = 'toast-container';
          document.body.appendChild(container);

          instance = new ToastConstructor();
          instance.$mount(container);

          Vue.prototype.$toast = {
            show(options) {
              instance.addToast(options);
            },
          };
        }
      },
    });
  },
};

export default ToastPlugin;
