var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":_vm.toastAnimation}},[(_vm.visible)?_c('div',{class:[
      'toast bg-white',
      `text-${_vm.color}-500`,
      _vm.positionClass,
      'animate__animated',
      'px-4 py-4 pb-6',
      'relative rounded-xl shadow-lg border select-none',
    ],on:{"mousedown":_vm.handleMouseStart,"mouseleave":_vm.handleMouseLeave,"mousemove":_vm.handleMouseMove,"mouseup":_vm.handleMouseEnd,"touchend":_vm.handleTouchEnd,"touchmove":_vm.handleTouchMove,"touchstart":_vm.handleTouchStart}},[_c('div',{staticClass:"flex items-center justify-between gap-10"},[_c('div',{staticClass:"flex items-center gap-3"},[_c('div',{staticClass:"p-2",class:['rounded-xl', `bg-${_vm.color}-100`, `text-${_vm.color}-500`]},[_c('Icon',{staticClass:"w-6 h-6",attrs:{"icon":_vm.svgIcon}})],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-1"},[_c('h1',{staticClass:"font-semibold",class:`text-${_vm.color}-500`},[_vm._v("\n            "+_vm._s(_vm.title)+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"text-xs text-gray-500"},[_vm._v(_vm._s(_vm.description))])])]),_vm._v(" "),_c('div',[_c('button',{staticClass:"text-gray-400 transition-all duration-300 hover:text-gray-800",on:{"click":_vm.closeToast}},[_c('svg',{attrs:{"height":"18px","viewBox":"0 0 24 24","width":"18px","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z","fill":"currentColor"}})])])])]),_vm._v(" "),_c('div',{staticClass:"absolute bottom-0 left-0 h-2 rotate-180 rounded-tl-3xl rounded-tr-3xl shadow-lg",class:['progress-bar', `bg-${_vm.color}-500`, `shadow-${_vm.color}-500`],style:({ width: _vm.progressBarWidth })})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }