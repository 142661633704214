import PaymentMethods from '../../api/paymentMethods';
import types from '../mutation-types';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
  },
};

export const getters = {
  getPaymentMethods($state) {
    return $state.records;
  },
  getPaymentMethod: $state => paymentMethodId => {
    const paymentMethod = $state.records.find(
      record => record.id === Number(paymentMethodId)
    );
    return paymentMethod || {};
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  async fetchPaymentMethods({ commit }) {
    commit(types.SET_PAYMENT_METHODS_UI_FLAG, { isFetching: true });
    try {
      const response = await PaymentMethods.getPaymentMethods();
      commit(types.SET_PAYMENT_METHODS, response.data);
      return response.data;
    } catch (error) {
      commit(types.SET_PAYMENT_METHODS_UI_FLAG, { isFetching: false });
      throw new Error(error);
    } finally {
      commit(types.SET_PAYMENT_METHODS_UI_FLAG, { isFetching: false });
    }
  },
  async fetchPaymentMethod({ commit }, paymentMethodId) {
    commit(types.SET_PAYMENT_METHODS_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await PaymentMethods.getPaymentMethod(paymentMethodId);
      commit(types.SET_PAYMENT_METHOD, response.data);
      return response.data;
    } catch (error) {
      commit(types.SET_PAYMENT_METHODS_UI_FLAG, { isFetchingItem: false });
      throw new Error(error);
    } finally {
      commit(types.SET_PAYMENT_METHODS_UI_FLAG, { isFetchingItem: false });
    }
  },
};

export const mutations = {
  [types.SET_PAYMENT_METHODS_UI_FLAG]($state, uiFlag) {
    $state.uiFlags = { ...$state.uiFlags, ...uiFlag };
  },
  [types.SET_PAYMENT_METHODS]($state, paymentMethods) {
    $state.records = paymentMethods;
  },
  [types.SET_PAYMENT_METHOD]($state, paymentMethod) {
    const index = $state.records.findIndex(
      record => record.id === paymentMethod.id
    );
    if (index !== -1) {
      $state.records.splice(index, 1, paymentMethod);
    } else {
      $state.records.push(paymentMethod);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
