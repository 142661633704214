import settings from './settings/settings.routes';
import conversation from './conversation/conversation.routes';
import { routes as searchRoutes } from '../../modules/search/search.routes';
import { routes as contactRoutes } from './contacts/routes';
import { routes as notificationRoutes } from './notifications/routes';
import { routes as inboxRoutes } from './inbox/routes';
import { frontendURL } from '../../helper/URLHelper';
import helpcenterRoutes from './helpcenter/helpcenter.routes';
// import MultiStep from '../../../v4/pages/MultiStep.vue';

const AppContainer = () => import('./Dashboard.vue');
const Suspended = () => import('./suspended/Index.vue');
const MultiStep = () => import('./multistep/Index.vue');
const Payment = () => import('./payment/Index.vue');
const PaymentHistoryDetail = () => import('./payment-history/Index.vue');
const Invoice = () => import('./payment-history/Invoice.vue');
const HomePage = () => import('../../../home/pages/HomePage.vue');
const FeaturePage = () => import('../../../home/pages/Features.vue');
const PricingPage = () => import('../../../home/pages/Pricing.vue');
const HelpCenterPage = () => import('../../../home/pages/HelpCenter.vue');

export default {
  routes: [
    ...helpcenterRoutes.routes,
    {
      path: frontendURL('accounts/:account_id'),
      component: AppContainer,
      children: [
        ...inboxRoutes,
        ...conversation.routes,
        ...settings.routes,
        ...contactRoutes,
        ...searchRoutes,
        ...notificationRoutes,
        {
          path: frontendURL('accounts/:accountId/payment'),
          name: 'account_payment',
          roles: ['administrator', 'agent'],
          component: Payment,
        },
        {
          path: frontendURL('accounts/:accountId/payment_history/:payment_id'),
          name: 'account_payment_history',
          roles: ['administrator', 'agent'],
          component: PaymentHistoryDetail,
          props: route => ({ paymentId: route.params.payment_id }),
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/multistep'),
      name: 'account_multistep',
      roles: ['administrator', 'agent'],
      component: MultiStep,
    },
    {
      path: frontendURL('accounts/:accountId/suspended'),
      name: 'account_suspended',
      roles: ['administrator', 'agent'],
      component: Suspended,
    },
    {
      path: '/',
      name: 'Home',
      component: HomePage,
    },
    {
      path: '/features',
      name: 'Features',
      component: FeaturePage,
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: PricingPage,
    },
    {
      path: '/help-center',
      name: 'Help Center',
      component: HelpCenterPage,
    },
  ],
};
