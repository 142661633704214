/* global axios */
import endPoints from './endPoints';
import CacheEnabledApiClient from './CacheEnabledApiClient';

class PaymentMethods extends CacheEnabledApiClient {
  constructor() {
    super('payment_methods', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'payment_methods';
  }

  getPaymentMethod(id) {
    return axios.get(`${this.url}/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  getPaymentMethods() {
    return axios.get(endPoints('payment_methods').url);
  }
}

export default new PaymentMethods();
