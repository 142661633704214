/* global axios */
import CacheEnabledApiClient from './CacheEnabledApiClient';

class Inboxes extends CacheEnabledApiClient {
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'inbox';
  }

  getCampaigns(inboxId) {
    return axios.get(`${this.url}/${inboxId}/campaigns`);
  }

  deleteInboxAvatar(inboxId) {
    return axios.delete(`${this.url}/${inboxId}/avatar`);
  }

  deleteInboxWidgetIcon(inboxId) {
    return axios.delete(`${this.url}/${inboxId}/destroy_channel_web_widget_icon`);
  }

  getDomains(inboxId) {
    return axios.get(`${this.url}/${inboxId}/get_domain_channels`);
  }

  removeDomain(inboxId, domainId) {
    return axios.delete(`${this.url}/${inboxId}/remove_domain_channels`, {
      data: { domain_channel_id: domainId },
    });
  }

  addDomainToTrusted(inboxId, domainId) {
    return axios.post(`${this.url}/${inboxId}/add_domain_to_trusted`, {
      domain_channel_id: domainId,
    });
  }

  createDomainChannels(inboxId, domain) {
    return axios.post(`${this.url}/${inboxId}/create_domain_channels`, {
      domain,
    });
  }

  getAgentBot(inboxId) {
    return axios.get(`${this.url}/${inboxId}/agent_bot`);
  }

  setAgentBot(inboxId, botId) {
    return axios.post(`${this.url}/${inboxId}/set_agent_bot`, {
      agent_bot: botId,
    });
  }
}

export default new Inboxes();
