<template>
  <div>
    <div
      v-for="position in positions"
      :id="'toast-container-' + position"
      :key="position"
      :class="getPositionClass(position)"
      class="fixed flex flex-col gap-2"
    >
      <Toast
        v-for="(toast, index) in toasts.filter(t => t.position === position)"
        :key="index"
        v-bind="toast"
        @close="removeToast(index)"
      />
    </div>
  </div>
</template>

<script>
import Toast from './Toast.vue';

export default {
  components: {
    Toast,
  },
  data() {
    return {
      toasts: [],
      positions: [
        'top-left',
        'top-right',
        'top-center',
        'bottom-left',
        'bottom-right',
        'bottom-center',
      ],
    };
  },
  methods: {
    addToast(toast) {
      const defaultToast = {
        title: 'Default Title',
        description: 'Your data has been saved successfully.',
        variant: 'success',
        duration: 5,
        position: 'top-right',
        visible: true,
      };

      this.toasts.push({ ...defaultToast, ...toast });
    },
    removeToast(index) {
      this.toasts.splice(index, 1);
    },
    getPositionClass(position) {
      return {
        'top-left': 'top-5 left-5',
        'top-right': 'top-5 right-5',
        'top-center': 'top-5 left-1/2 transform -translate-x-1/2',
        'bottom-left': 'bottom-5 left-5',
        'bottom-right': 'bottom-5 right-5',
        'bottom-center': 'bottom-5 left-1/2 transform -translate-x-1/2',
      }[position];
    },
  },
};
</script>

<style scoped>
.fixed {
  z-index: 9999;
}
</style>
