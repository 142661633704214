const state = {
  menu: 'overview',
};

const getters = {
  getMenu: $state => $state.menu,
};

const actions = {
  setMenu({ commit }, menu) {
    commit('setMenu', menu);
  },
};

const mutations = {
  setMenu($state, menu) {
    $state.menu = menu;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
