import VueRouter from 'vue-router';
import dashboard from './dashboard/dashboard.routes';
import store from '../store';
import AnalyticsHelper from '../helper/AnalyticsHelper';
import { frontendURL } from '../helper/URLHelper';

const routes = [...dashboard.routes];

window.roleWiseRoutes = {
  agent: [],
  administrator: [],
};

// generateRoleWiseRoute - updates window object with agent/admin route
const generateRoleWiseRoute = route => {
  route.forEach(element => {
    if (element.children) {
      generateRoleWiseRoute(element.children);
    }
    if (element.roles) {
      element.roles.forEach(roleEl => {
        window.roleWiseRoutes[roleEl].push(element.name);
      });
    }
  });
};
// Create a object of routes
// accessible by each role.
// returns an object with roles as keys and routeArr as values
generateRoleWiseRoute(routes);

export const router = new VueRouter({ mode: 'history', routes });

export const validateAuthenticateRoutePermission = async (
  to,
  next,
  { getters },
) => {
  const {
    isLoggedIn,
    getCurrentUser: user,
    // getCurrentAccount: account,
  } = getters;
  const currentUserAccountId = user.account_id;
  const toParams = to.params.accountId;

  const filteredAccount = user.accounts.filter(acc => acc.id == toParams);
  const planSeen = localStorage.getItem('planSeen');
  if (user && user.account_id && !localStorage.getItem('is_logged_in')) {
    localStorage.setItem('is_logged_in', true);
    localStorage.setItem('account_id', user.account_id);
  } else if (user && !user.account_id && localStorage.getItem('is_logged_in')) {
    localStorage.removeItem('is_logged_in');
    localStorage.removeItem('account_id');
  }

  if (
    user.sign_in_count === 1 &&
    filteredAccount.length > 0 &&
    filteredAccount[0].role === 'administrator' &&
    filteredAccount[0].package &&
    filteredAccount[0].package.id === 1 &&
    !to.path.includes('profile/settings') &&
    planSeen !== 'true'
  ) {
    window.location = `/app/accounts/${currentUserAccountId}/profile/settings?tab=plans`;
    return `/app/accounts/${currentUserAccountId}/profile/settings?tab=plans`;
  }

  // if (isLoggedIn && toParams != currentUserAccountId) {
  //   window.location = `/app/accounts/${currentUserAccountId}/dashboard`;
  //   return `/app/accounts/${currentUserAccountId}/dashboard`;
  // }

  if (!isLoggedIn && to.path.includes('accounts')) {
    window.location = '/app/login';
    return '/app/login';
  }

  const path = to.path.match(/\d+/g);
  if (path && path.length > 0) {
    const inbox_count = user.accounts.filter(account => account.id == path[0]);
    if (inbox_count.length > 0) {
      const skippedData = JSON.parse(localStorage.getItem('skippedData')) || {};
      if (
        inbox_count.length > 0 &&
        inbox_count[0].role &&
        inbox_count[0].inboxes_count === 0 &&
        !skippedData[path[0]] &&
        inbox_count[0].role !== 'agent'
      ) {
        if (!to.path.includes('multistep')) {
          window.location = `/app/accounts/${path}/multistep`;
          return `/app/accounts/${path}/multistep`;
        }
      } else if (to.path.includes('multistep')) {
        window.location = `/app/accounts/${path}/dashboard`;
        return `/app/accounts/${path}/dashboard`;
      }
    }
  }

  // if (!isLoggedIn) {
  //   window.location = '/app/login';
  //   return '/app/login';
  // }

  if (!to.name && isLoggedIn) {
    return next(frontendURL(`accounts/${user.account_id}/dashboard`));
  }

  if (!to.name && !isLoggedIn) {
    window.location = '/app/login';
    return '/app/login';
  }
  //
  // const nextRoute = validateLoggedInRoutes(
  //   to,
  //   getters.getCurrentUser,
  //   window.roleWiseRoutes
  // );

  return next();
};

export const initalizeRouter = () => {
  const userAuthentication = store.dispatch('setUser');

  router.beforeEach((to, from, next) => {
    AnalyticsHelper.page(to.name || '', {
      path: to.path,
      name: to.name,
    });

    userAuthentication.then(() => {
      return validateAuthenticateRoutePermission(to, next, store);
    });
  });
};

export default router;
