import { frontendURL } from '../../../../helper/URLHelper';
// import EditOverview from './Menu/EditOverview.vue';

const SettingsContent = () => import('./Wrapper.vue');
const Index = () => import('./Index.vue');
const IndexSidebar = () => import('./IndexSidebar.vue');
const EditOverview = () => import('./Menu/EditOverview.vue');
export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/profile'),
      name: 'profile_settings',
      roles: ['administrator', 'agent'],
      component: SettingsContent,
      children: [
        {
          path: 'settings',
          name: 'profile_settings_index',
          component: IndexSidebar,
          roles: ['administrator', 'agent'],
        },
        {
          path: 'edit',
          name: 'profile_settings_edit',
          component: EditOverview,
          roles: ['administrator', 'agent'],
        },
      ],
    },
  ],
};
