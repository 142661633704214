/* global axios */
import endPoints from './endPoints';
import CacheEnabledApiClient from './CacheEnabledApiClient';

class Packages extends CacheEnabledApiClient {
  constructor() {
    super('packages', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'packages';
  }

  getPackage(id) {
    return axios.get(`${this.url}/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  getPackages() {
    return axios.get(endPoints('packages').url);
  }
}

export default new Packages();
