<template>
  <transition :name="toastAnimation">
    <div
      v-if="visible"
      :class="[
        'toast bg-white',
        `text-${color}-500`,
        positionClass,
        'animate__animated',
        'px-4 py-4 pb-6',
        'relative rounded-xl shadow-lg border select-none',
      ]"
      @mousedown="handleMouseStart"
      @mouseleave="handleMouseLeave"
      @mousemove="handleMouseMove"
      @mouseup="handleMouseEnd"
      @touchend="handleTouchEnd"
      @touchmove="handleTouchMove"
      @touchstart="handleTouchStart"
    >
      <div class="flex items-center justify-between gap-10">
        <div class="flex items-center gap-3">
          <div
            :class="['rounded-xl', `bg-${color}-100`, `text-${color}-500`]"
            class="p-2"
          >
            <Icon :icon="svgIcon" class="w-6 h-6" />
          </div>
          <div class="flex flex-col gap-1">
            <h1 :class="`text-${color}-500`" class="font-semibold">
              {{ title }}
            </h1>
            <p class="text-xs text-gray-500">{{ description }}</p>
          </div>
        </div>
        <div>
          <button
            class="text-gray-400 transition-all duration-300 hover:text-gray-800"
            @click="closeToast"
          >
            <svg
              height="18px"
              viewBox="0 0 24 24"
              width="18px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        :class="['progress-bar', `bg-${color}-500`, `shadow-${color}-500`]"
        :style="{ width: progressBarWidth }"
        class="absolute bottom-0 left-0 h-2 rotate-180 rounded-tl-3xl rounded-tr-3xl shadow-lg"
      />
    </div>
  </transition>
</template>

<script>
import { Icon } from '@iconify/vue2';

export default {
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      default: 'Default Title',
    },
    description: {
      type: String,
      default: 'Your data has been saved successfully.',
    },
    variant: {
      type: String,
      default: 'info',
      validator(value) {
        return ['success', 'error', 'warning', 'info'].includes(value);
      },
    },
    position: {
      type: String,
      default: 'top-right',
      validator(value) {
        return [
          'top-left',
          'top-right',
          'top-center',
          'bottom-left',
          'bottom-right',
          'bottom-center',
        ].includes(value);
      },
    },
    duration: {
      type: Number,
      default: 5, // default duration in seconds
    },
  },
  data() {
    return {
      visible: true,
      progressBarWidth: '100%',
      touchStartX: 0,
      touchStartY: 0,
      touchEndX: 0,
      touchEndY: 0,
      isMouseDown: false,
    };
  },
  computed: {
    color() {
      return {
        success: 'green',
        error: 'red',
        warning: 'yellow',
        info: 'blue',
      }[this.variant];
    },
    svgIcon() {
      return {
        success: 'bxs:check-circle',
        error: 'bxs:error-circle',
        warning: 'mdi:warning-circle',
        info: 'bxs:info-circle',
      }[this.variant];
    },
    positionClass() {
      return {
        'top-left': 'top-5 left-5',
        'top-right': 'top-5 right-5',
        'top-center': 'top-5 left-1/2 transform -translate-x-1/2',
        'bottom-left': 'bottom-5 left-5',
        'bottom-right': 'bottom-5 right-5',
        'bottom-center': 'bottom-5 left-1/2 transform -translate-x-1/2',
      }[this.position];
    },
    toastAnimation() {
      return {
        'top-center': 'animate__fadeInDown animate__fadeOutUp',
        'top-left': 'animate__fadeInLeft animate__fadeOutLeft',
        'top-right': 'animate__fadeInRight animate__fadeOutRight',
        'bottom-center': 'animate__fadeInUp animate__fadeOutDown',
        'bottom-left': 'animate__fadeInLeft animate__fadeOutLeft',
        'bottom-right': 'animate__fadeInRight animate__fadeOutRight',
      }[this.position];
    },
  },
  mounted() {
    this.startProgress();
  },
  methods: {
    closeToast() {
      this.visible = false;
    },
    startProgress() {
      const decrement = 100 / (this.duration * 20); // Calculate decrement based on 20 steps per second
      let width = 100;

      const interval = setInterval(() => {
        if (width > 0) {
          width -= decrement;
          this.progressBarWidth = `${width}%`;
        } else {
          clearInterval(interval);
          this.closeToast();
        }
      }, 50);
    },
    handleTouchStart(e) {
      this.touchStartX = e.touches[0].clientX;
      this.touchStartY = e.touches[0].clientY;
    },
    handleTouchMove(e) {
      this.touchEndX = e.touches[0].clientX;
      this.touchEndY = e.touches[0].clientY;
    },
    handleTouchEnd() {
      this.handleGesture();
    },
    handleMouseStart(e) {
      this.isMouseDown = true;
      this.touchStartX = e.clientX;
      this.touchStartY = e.clientY;
    },
    handleMouseMove(e) {
      if (this.isMouseDown) {
        this.touchEndX = e.clientX;
        this.touchEndY = e.clientY;
      }
    },
    handleMouseEnd() {
      this.isMouseDown = false;
      this.handleGesture();
    },
    handleMouseLeave() {
      this.isMouseDown = false;
    },
    handleGesture() {
      const deltaX = this.touchEndX - this.touchStartX;
      const deltaY = this.touchEndY - this.touchStartY;
      const absDeltaX = Math.abs(deltaX);
      const absDeltaY = Math.abs(deltaY);

      if (absDeltaX > absDeltaY && absDeltaX > 50) {
        if (deltaX > 0) {
          this.closeToastWithAnimation('animate__fadeOutRight');
        } else {
          this.closeToastWithAnimation('animate__fadeOutLeft');
        }
      } else if (absDeltaY > absDeltaX && absDeltaY > 50) {
        if (deltaY > 0) {
          this.closeToastWithAnimation('animate__fadeOutDown');
        } else {
          this.closeToastWithAnimation('animate__fadeOutUp');
        }
      }
    },
    closeToastWithAnimation(animationClass) {
      this.$el.classList.remove(
        'animate__fadeInRight',
        'animate__fadeInDown',
        'animate__fadeInLeft',
        'animate__fadeInUp'
      );
      this.$el.classList.add(animationClass);
      setTimeout(() => {
        this.closeToast();
      }, 500); // Match this duration with the CSS transition duration
    },
  },
};
</script>

<style scoped>
.toast {
  margin: 10px 0;
}

.progress-bar {
  transition:
    width 1s linear,
    border-radius 0.5s ease !important;
}
</style>
